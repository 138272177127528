<template>
  <v-container>
    <Editor select autoTexto calculadoraVolume :value="valor"></Editor>
  </v-container>
</template>

<script>
import Editor from "../components/comum/Editor";

export default {
  name: "editor-teste",
  data() {
    return {
      valor: `
      <p><strong>TÉCNICA</strong></p>
<p></p>
<p>
  Exame realizado em aparelho
  <select-component
    opcoes=" 02 canais | multislice de 16 canais"
    selecionado=""
    >multislice de 16 canais</select-component
  >,<select-component
    opcoes="sem uso de meio de contraste | sem uso de meio de contraste e tendo sido feito reconstrução de imagens em 3D | antes e após infusão endovenosa do meio de contraste, com protocolo específico de avaliação das vias urinárias | sem uso do meio de contraste iodado, tendo em vista os antecedentes alérgicos referidos na história clínica | antes e depois da infusão endovenosa do meio de contraste, tendo o paciente feito uso prévio da substância contrastante hidrossolúvel por via retal | antes e após infusão endovenosa do meio de contraste, com protocolo específico de avaliação das vias urinárias 2"
    selecionado="antes e após infusão endovenosa do meio de contraste, com protocolo específico de avaliação das vias urinárias"
    >antes e após infusão endovenosa do meio de contraste, com protocolo
    específico de avaliação das vias urinárias </select-component
  >.
</p>
<p></p>
<p></p>
<p><strong>RELATÓRIO</strong></p>
<p></p>
<p></p>
<p>
  Estruturas pulmonares e mediastinais inferiores sem alterações
  significativas.</p><p>Fígado de contornos, dimensões e densidade normais.
  Também não há dilatação das vias biliares intra e extra-hepáticas.&nbsp;</p><p>O
  pâncreas e o baço apresentam situação, forma, tamanho e densidade normais.</p><p>Glândulas
  adrenais inalteradas.&nbsp;</p><p>Rins tópicos, com dimensões, forma e
  densidade habituais. O nefrograma e a eliminação do meio de contraste estão
  preservados, bilateralmente.&nbsp;</p><p>Não existem linfonodos
  intra-abdominais de tamanho aumentado.&nbsp;</p><p>Aorta pérvia, com calibre e
  trajeto normais.&nbsp;</p><p>Bexiga com expansibilidade uniforme, limites
  nítidos e opacificação homogênea. &nbsp;</p><p>Apêndice cecal de aspecto
  tomográfico preservado.</p><p>Pequena quantidade de liquido livre no escavado
  pelvico.
</p>
<p></p>
<p></p>
<p><strong>CONCLUSÃO</strong></p>
<p></p>
<p></p>
<p>Exame tomográfico sem alterações significativas</p>



      `
    };
  },
  components: {
    Editor
  },

  methods: {},

  created() {
    this.$store.dispatch("setLoginLayout");
  }
};
</script>
